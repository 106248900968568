import React, { useCallback, useEffect, useState } from 'react'
import ReactModalOriginal from 'react-modal'
import { createGlobalStyle } from 'styled-components'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 200ms ease;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
`

ReactModalOriginal.setAppElement(`#___gatsby`)

const customStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    top: '50vh',
    left: '50vw',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
}

export const useModal = (initialValue: boolean = false) => {
  const [isOpen, setIsOpen] = useState(initialValue)

  const onRequestClose = useCallback(() => setIsOpen(false), [])
  const requestOpen = useCallback(() => setIsOpen(true), [])

  return { isOpen, setIsOpen, requestOpen, onRequestClose }
}

const useModalLockBodyScroll = (isOpen: boolean) => {
  const [bodyLocked, setBodyLocked] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setBodyLocked(true)
    }
  }, [isOpen])

  useLockBodyScroll(bodyLocked)

  const unlock = useCallback(() => setBodyLocked(false), [])
  return unlock
}

interface ModalProps {
  contentLabel?: string
  isOpen: boolean
  onRequestClose: () => void
  onAfterClose?: () => void
  style?: ReactModalOriginal.Styles
  children: React.ReactNode
}

const ReactModal: React.FC<ModalProps> = ({
  contentLabel,
  isOpen,
  onRequestClose,
  children,
  onAfterClose,
  style,
}) => {
  const unlock = useModalLockBodyScroll(isOpen)

  const handleAfterClose = useCallback(() => {
    unlock()
    onAfterClose && onAfterClose()
  }, [unlock, onAfterClose])

  const { overlay, content, ...rest } = style ?? {}

  return (
    <>
      <GlobalStyle />
      <ReactModalOriginal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onAfterClose={handleAfterClose}
        style={{
          overlay: {
            ...customStyles.overlay,
            ...overlay,
          },
          content: {
            ...customStyles.content,
            ...content,
          },
          ...rest,
        }}
        contentLabel={contentLabel}
        closeTimeoutMS={200}
      >
        {children}
      </ReactModalOriginal>
    </>
  )
}

export default ReactModal
