import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactModal from './ReactModal'
import styled from 'styled-components'
import CloseArrowIcon from '../images/CloseArrow.svg'

const customStyles = {
  content: {
    border: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.75)',
    borderRadius: '10px',
    padding: 0,
  },
}

const Container = styled.div`
  min-width: 280px;
  position: relative;
  display: flex;
`

const CloseButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 8px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const Message = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
`

const Payload = styled.div`
  padding: 10px;
`

const Hero = styled.div`
  margin-top: 15px;
`

interface ModalProps {
  isOpen: boolean
  onRequestClose: () => void
  message?: React.ReactNode
  showHero?: boolean
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  showHero,
  message,
}) => {
  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <Container>
          <CloseButton onClick={onRequestClose}>
            <img src={CloseArrowIcon} width={29} height={29} />
          </CloseButton>
          <Content>
            {showHero && (
              <Hero>
                <StaticImage
                  src="../images/LogoModal.png"
                  alt="save"
                  width={108}
                  height={110}
                  placeholder="blurred"
                  quality={100}
                />
              </Hero>
            )}
            {message && <Message>{message}</Message>}
            <Payload>{children}</Payload>
          </Content>
        </Container>
      </ReactModal>
    </>
  )
}

export default Modal
